<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="分账关系编号">
          <el-input
            placeholder="请输入分账关系编号"
            v-model="searchForm.number"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="所属主体">
          <AgentTree
            :props="props"
            clearable
            style="width: 300px"
            v-model="searchForm.unitNo"
          >
          </AgentTree>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="searchForm.time"
            clearable
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <div>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
              <el-button type="primary" @click="goAdd">新增</el-button>
          </el-form-item>
        </div>

      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="commodityNo" fixed min-width="250" label="分账关系编号">
        </el-table-column>
        <el-table-column prop="createTime" width="200" label="创建时间">
           <template slot-scope="{row}">
            {{ row.createTime}}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="关联商品类型"  min-width="150">
          <template slot-scope="{row}">
            {{$changeObj(GOODS_TYPE)[row.type]}}
          </template>
        </el-table-column>
        <el-table-column prop="standard" label="关联商品规格"  min-width="150">
        </el-table-column>

        <el-table-column prop="title" label="关联方案名称"  min-width="200"></el-table-column>
        <el-table-column prop="price" label="方案单期租金"  min-width="120">
          <template v-if="row.price" slot-scope="{row}">
            {{row.price}}元
          </template>
        </el-table-column>
        <el-table-column prop="validityTime" width="120" label="方案有效时长">
          <template v-if="row.validityTime" slot-scope="{row}">
            {{row.validityTime}}天
          </template>
        </el-table-column>
        <el-table-column prop="relationUnitName" width="200" label="所属主体">
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  listDistributeRelation,
  delDisRelation
} from "@/api/cash";
import { timeToDate } from "@/utils/date-format";
import  AgentTree from '@/components/AgentTree'
import { RELATION_GOODS_TYPE ,GOODS_TYPE } from '@/js/constant'
import {deleteDistributeRelation} from '@/api/product'
export default {
  data() {
    return {
      searchForm: {
        unitNo: [],
        time: "",
        number: '',

      },
      agentList: [], //所属组织数据
      startTime: "",
      endTime: "",
      beginIndex: 1,
      pageSize: 20,
      list: [],
      total: 0,
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      RELATION_GOODS_TYPE,
      GOODS_TYPE,
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.onSearch()
      }
    };
    this.getMarketingActivityList();
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addSubBill') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.getMarketingActivityList();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.getMarketingActivityList();
    },
    handleChangeUnit(value) {
      this.searchForm.unitNo = value[value.length - 1];
    },
    async getMarketingActivityList() {
      if (this.searchForm.time) {
        this.startTime = this.searchForm.time[0];
        this.endTime = this.searchForm.time[1];
      } else {
        this.startTime = null;
        this.endTime = null;
      }
      let page = this.beginIndex;
      page = page - 1;
      const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];

      const params = {
        ...this.searchForm,
        beginTime: String(Date.parse(this.startTime)),
        endTime: String(Date.parse(this.endTime)),
        unitNo,
        beginIndex: page,
        pageSize: this.pageSize,

      };
      if (!this.endTime || !this.startTime) {
        params.beginTime = null;
        params.endTime = null;
      }
      delete params.time;
      const res = await listDistributeRelation(params);
      res.ldata.forEach(el => {
        if (el.createTime == "null") {
          el.createTime = null;
        }
        if (el.createTime && el.createTime != "null") {
          el.createTime = timeToDate(el.createTime);
        }
        el.loading = false;
      });
      this.list = res.ldata;
      this.total = +res.mdata.total || 0;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getMarketingActivityList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**删除 */
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            commodityNo: row.commodityNo
          };
          await deleteDistributeRelation(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.getMarketingActivityList();
        })
        .catch(() => {});
    },
    goAdd() {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/fund/lease/createDistributeRelation' ).then((flag) => {
        if(flag) {
          this.$router.push("/leaseholdMgmt/addSubBill");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push("/leaseholdMgmt/addSubBill");
      })
      
    },
    goEdit(row) {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/fund/lease/updateDistributeRelation' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addSubBill",
            query: {
              number: row.number,
              type: "edit",
              goodsType: row.type,
              unitNo: row.unitNo,
              commodityNo:row.commodityNo
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addSubBill",
          query: {
            number: row.number,
            type: "edit",
            goodsType: row.type,
            unitNo: row.unitNo,
            commodityNo:row.commodityNo
          }
        });
      })
      
    }
  },
  components: {
    AgentTree,
  }
};
</script>

<style scoped>
.library {
  padding-bottom: 80px;
}
</style>
